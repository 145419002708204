<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Country") }}</div>
    <div class="details">{{ contentFormInfo.country_name }}</div>
    <div class="titles">{{ __("Region") }}</div>
    <div class="details">{{ contentFormInfo.region_name }}</div>
    <div class="titles">{{ __("Prefix") }}</div>
    <div class="details">{{ contentFormInfo.prefix }}</div>
    <div class="titles">{{ __("Monthly Number Rental") }}</div>
    <div class="details">{{ contentFormInfo.monthly_number_rental }}</div>
    <div class="titles">{{ __("Inbound Rate Per SMS") }}</div>
    <div class="details">{{ contentFormInfo.inbound_rate_per_sms }}</div>
    <div class="titles">{{ __("Outbound Rate Per SMS") }}</div>
    <div class="details">{{ contentFormInfo.outbound_rate_per_sms }}</div>
    <el-button @click="handleEdit" class="editBtn" v-show="this.canWrite()">
      {{ __("Edit") }}
    </el-button>
    <el-button
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      v-show="this.canWrite()"
    >
      {{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);" v-loading="isLoading">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header
                  :title="__('Region')"
                  :contentId="id"
                ></page-header>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="country_name"
                  :label="__('Country Name')"
                  required
                >
                  <el-autocomplete
                    style="width: 100%"
                    v-model="contentForm.country_name"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                  >
                  </el-autocomplete>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="region_name"
                  :label="__('Region Name')"
                  required
                >
                  <el-input v-model="contentForm.region_name" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="prefix" :label="__('Prefix')" required>
                  <el-input v-model="contentForm.prefix" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="monthly_number_rental"
                  :label="__('Monthly Number Rental')"
                >
                  <el-input-number
                    v-model="contentForm.monthly_number_rental"
                    ref="monthly_number_rental"
                    :precision="2"
                    :step="0.5"
                    :min="0.0"
                    :max="500000"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="inbound_rate_per_sms"
                  :label="__('Inbound Rate Per SMS')"
                >
                  <el-input-number
                    v-model="contentForm.inbound_rate_per_sms"
                    ref="inbound_rate_per_sms"
                    :precision="2"
                    :step="0.5"
                    :min="0.0"
                    :max="500000"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="outbound_rate_per_sms"
                  :label="__('Outbound Rate Per SMS')"
                >
                  <el-input-number
                    v-model="contentForm.outbound_rate_per_sms"
                    ref="outbound_rate_per_sms"
                    :precision="2"
                    :step="0.5"
                    :min="0.0"
                    :max="500000"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="createOrEditRegion"
              class="submitBtn"
              v-loading="isLoading"
              v-show="this.canWrite()"
              :disabled="contentForm.region_name === ''"
              :primaryKey="id"
              variant="AddUpdate"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  props: {
    regionToEdit: {
      type: Object,
      required: false,
      default: () => ({
        country_name: "",
        region_name: "",
        monthly_number_rental: "",
        inbound_rate_per_sms: "",
        outbound_rate_per_sms: "",
        prefix: ""
      })
    },
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      isSubmitting: false,
      regionFormKey: 0,
      isReceiving: false,
      region: {},
      links: [],
      rules: {
        country_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Country Name is required.")
          }
        ],
        region_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Region Name is required.")
          }
        ],
        prefix: [
          {
            required: true,
            trigger: "blur",
            message: __("Prefix is required.")
          }
        ],
        monthly_number_rental: [
          {
            required: true,
            trigger: "blur",
            message: __("Monthly Rental price is required.")
          }
        ],
        inbound_rate_per_sms: [
          {
            required: true,
            trigger: "blur",
            message: __("Inbound Rate is required.")
          }
        ],
        outbound_rate_per_sms: [
          {
            required: true,
            trigger: "blur",
            message: __("Outbound Rate is required.")
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("regions", {
      isLoading: state => state.isLoading,
      regions: state => state.regions
    })
  },

  beforeRouteLeave(to, from, next) {
    this.region = {};
    next();
  },

  methods: {
    ...mapActions("regions", {
      createRegion: "createRegionAction",
      updateRegion: "updateRegionAction",
      deleteContentMethod: "deleteRegionAction",
      undoDeleteContent: "undoDeleteContent"
    }),

    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    querySearch(queryString, cb) {
      var links = this.links;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    loadAll() {
      this.links = this.regions.length
        ? _.uniqBy(
            _.map(this.regions, function(item) {
              return {
                value: item.country_name
              };
            }),
            "value"
          )
        : [];
    },

    createOrEditRegion() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createRegion : this.updateRegion;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Region added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Region updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "edit" || val === "add") {
          this.loadAll();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }

  .el-select__tags {
    ::v-deep .el-tag {
      background-color: $content-theme-color !important;
      border-color: $content-theme-color !important;

      .el-tag__close {
        background-color: $content-theme-color !important;
      }
    }
  }
}

::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}

::v-deep .el-slider {
  border-color: $content-theme-color;
  :hover {
    border-color: $content-theme-color;
  }

  .el-slider__bar {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-slider__button {
    border-color: $content-theme-color;
    color: $content-theme-color;
  }

  ::v-deep .el-input-number--small .el-input-number__increase {
    :hover {
      color: $content-theme-color;
    }
  }
  ::v-deep .el-input-number--small .el-input-number__decrease {
    :hover {
      color: $content-theme-color;
    }
  }
}
.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}
</style>
