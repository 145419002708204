<script>
import List from "./pages/listRegion";
import CreateOrEdit from "./pages/createOrEditRegion";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "RegionsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("regions", {
      contents: state => state.regions
    })
  },

  methods: {
    initContent() {
      return {
        ac_id: "",
        sp_plan_id: "",
        unit_name: "",
        plan_type: "",
        allocated_unit: 0,
        burst_unit: 0,
        allow_bursting: false,
        qfm_pooling_method: "Strict"
      };
    }
  },
  mounted() {
    this.contentType = "Region";
    this.primaryKey = "region_id";
    this.scope = "sys";
  }
};
</script>
<style lang="scss" scoped></style>
